<template>
    <div class="overview-area pt-100 pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Call Us</h3>
                        <span>
                            <a href="tel:+91">+919876543210</a>
                        </span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Email Us</h3>
                        <span>
                            <a href="mailto:demo@gmail.com">demo@gmail.com</a>
                        </span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Call Us</h3>
                        <span>
                            <a href="tel:+91">+919876543210</a>
                        </span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Address,</h3>
                        <span>
                            தேவர் மகாஜன சங்கம் <br>
                            க.எண்.35/F8 முதல் F18வரை,<br>
                            எட்டயாபுரம் ரோடு,<br>
                            விளாத்திகுளம்,<br>
                            தூத்துக்குடி மாவட்டம்.
                        </span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Overview'
}
</script>
<template>
    <div>
        <Navbar />
        <MainBanner />
        <About />
        <!-- <ChooseUs />
        <Testimonials />
        <Partner /> -->
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../HomeTwo/MainBanner'
import About from '../HomeTwo/About'
// import ChooseUs from '../HomeTwo/ChooseUs'
// import Testimonials from '../HomeTwo/Testimonials'
// import Partner from '../Common/Partner'

import Footer from '../Layouts/Footer'

export default {
    name: 'HomePageTwo',
    components: {
        Navbar,
        MainBanner,
        About,
        // ChooseUs,
        // Testimonials,
        // Partner,
        Footer,
    }
}
</script>
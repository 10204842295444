<template>
    <div class="about-area pt-100 pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <div class="section-title mb-3" style="max-width: fit-content;">
                        <h2><b>Devar Thirumanamahal</b></h2>
                        <p class="text-dark">விளாத்திகுளம் வட்டார தேவர் மகாஜன சங்கம் மற்றும் அதன் கட்டிட வரலாறும்...</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="services-details-desc mx-0">
                <div class="article-services-content px-0">
                    <h5 class="text-dark mb-4 custom-title"><b>தோற்றம்:</b></h5>
                    <p class="text-dark">
                        விளாத்திகுளம் வட்டார தேவர் மகாஜன சங்கம் 1951ம் ஆண்டு துவங்கப்பட்டது.
                    </p>
                    <p class="text-dark">
                        சங்கத்தின் நிதி ஆதாரத்தினை பெருக்கும் பொருட்டு சுற்று வட்டார முக்குலத்தேவர் இன மக்களின் முக்கியமான வருமான
                        வாய்ப்பாக இருக்கிற விவசாய மகசூல் பொருட்களை விற்பனை செய்யும் போது அதற்கு மகிமை வசூல் செய்ய முடிவு 
                        செய்யப்பட்டது.
                    </p>
                    <p class="text-dark">
                        சங்கத்தின் தலைவராக துளசிபட்டி காலம் சென்ற திரு.T. மாடசாமி சேர்வை அவர்களையும் மற்றும் மற்ற கிராமங்களில் 
                        உள்ள முக்கியஸ்தர்கள் அனைவரையும் தேர்வு செய்யப்பட்டது.
                    </p>
                    <p class="text-dark">
                        வசூல் காரியதரிசியாக ஒருவர் நியமிக்கப்பட்டு முறையாக வசூல் செய்யப்பட்டது.
                    </p>
                    <p class="text-dark">
                        சங்கத்திற்கு என்று இடமும் கட்டிடம் வாங்க வேண்டும் என்று ஆலோசனை செய்யப்பட்டது.
                    </p>
                    <p class="text-dark">
                        அதற்காக விளாத்திகுளம் வட்டார நம் இன மக்கள் அனைவரிடமும் நன்கொடை வாங்க முடிவு செய்து எல்லோரிடமும் 
                        நன்கொடை கேட்டு வசூல் செய்யப்பட்டது.
                    </p>
                    <p class="text-dark">
                        இருப்பு நிதியுடன் நன்கொடையும் சேர்த்து ஒரு பெரும் தொகை சேர்க்கப்பட்டது.
                    </p>
                    <p class="text-dark">
                        அந்த கால கட்டத்தில் எட்டையாபுரம் அரண்மனைக்கு சொந்தமான வரிவசூல் செய்யும் கட்டிடம் மற்றும் பவுண்டரிஇடம் வினை விற்பனை 
                        செய்யப்போவதாக தெரிய வந்தது.
                    </p>
                    <p class="text-dark">
                        பொது காரியத்திற்கு வாங்குவது காலம்காலமாக சிறப்புடன் இருக்க வேண்டும் என்று அந்த திட்டத்தினை 
                        கைவிட்டுவிட்டார்கள்.
                    </p>
                    <p class="text-dark">
                        கை இருப்பில் உள்ள நன்கொடையுல் இடம் வாங்கி கட்டிடம் கட்டுவது சாத்தியம் இல்லை என்று  முடிவு செய்யப்பட்டது.
                    </p>
                    <p class="text-dark">
                        அப்பொழதைய தலைவராக இருந்துவந்து துளசிபட்டி காலம் சென்ற திரு.T.மாடசாமி சேர்வைகாரர் அவர்களுடைய இடத்தில்,
                        இருப்பு பணத்தினை வைத்து சங்க கட்டிடம் கட்டுவது என்று முடிவு செய்யப்பட்டது.
                    </p>
                    <p class="text-dark">
                        அதன்படி 1954ம் ஆண்டு கட்டிட வேலை கள் தொடங்கப்பட்டது கட்டிடம் இடத்தின் நடுமய்யத்தில் வடபுறம் பார்த்து கீழமேல் 
                        சாதியடி 25  தென்வடல் சாதியடி 35க்கு காரைகட்டு மட்டப்பாகு கட்டிடம் கட்டப்பட்டது.முன்புற தாழ்வாரம் அமைக்கப்பட்டது.
                    </p>
                    <p class="text-dark">
                        வருடாந்திர கூட்டம் மற்றும் அனைத்து உபயோகத்திற்கும் பயன்படும்படி கட்டிடம் முடிக்கப்பட்டது.
                    </p>
                </div>
                <div class="article-services-content px-0">
                    <h5 class="text-dark mb-4 custom-title"><b>கட்டிட கிரஹப்பிரவேசம்:</b></h5>
                    <p class="text-dark">
                        கட்டிடத்தின் கிரஹப்பிரவேஷத்திற்கு நமது இனத்தின் தெய்வத்திருமகன் தேவர் ஐயாவை அழைத்து நடத்திட முடிவு செய்யப்பட்டது.
                    </p>
                    <p class="text-dark">
                        தேவர் அவர்களுக்கும்  துளசிபட்டி க்கும் மிக நீண்ட நெடிய தொடர்பு இருந்து வந்த காரணத்தினால் கிரஹப்பிரவேஷத்திற்கு 
                        வர தேவர் திருமகனார் இசைவு தெரிவித்து விட்டார்கள்
                    </p>
                    <p class="text-dark">
                        அவ்வாறே 1955 ஆம் ஆண்டு தேவரய்யாவின் பர்மா விஜயத்திற்கு முன்பு அவருடைய தலைமையில் கிரஹப்பிரவேசம் நடைபெற்றது.
                    </p>
                </div>
                <div class="article-services-content px-0">
                    <h5 class="text-dark mb-4 custom-title"><b>கிரஹப்பிரவேஷ நிகழ்வு:</b></h5>
                    <p class="text-dark">
                        கிரஹப்பிரவேஷத்திற்கு முன் தேவர் அவர்களை குத்துவிளக்கு ஏற்றும்படி வேண்டிக் கொண்டார்கள்.
                    </p>
                    <p class="text-dark">
                        இன்று நான் விளக்கேற்றி வைப்பேன் ஆனால் வருங்காலத்தில் உங்களுக்குள் ஒற்றுமை குறைந்து 
                        விளக்கு எரியாமல் போட்டு விடுவீர்கள். ஆகையால் நான் விளக்கு ஏற்றுவது பொறுத்தமனதாக இருக்காது என்று மறுத்துவிட்டார்கள்.
                    </p>
                    <p class="text-dark">
                        அதன்பின் எல்லோரும் வேண்டிக் கொண்டப்படி 3மணி நேரம் மிக நெடிய சொற்பொழிவினை ஆற்றினார்கள். அதன் 
                        பிறகு நமது பகுதிக்கு வரவில்லை.
                    </p>
                </div>
                <div class="article-services-content px-0">
                    <h5 class="text-dark mb-4 custom-title"><b>இடத்தின் பத்திர பதிவு:</b></h5>
                    <p class="text-dark">
                        இடத்தில் கட்டிடம் கட்டி அனுபவித்து வந்து சுமார் 15 ஆண்டுகளுக்கு பின்பு 1969ம் ஆண்டு இடத்தின் தார்மீக 
                        உரிமையாளராக இருந்து வந்த துளசிபட்டி தெய்வத்திரு. T.  மாடசாமி சேர்வை மனைவி திருமதி.M.வேலம்மாள் 
                        அந்தக்கால கட்டத்தில் சங்கத்தின் தலைவராக இருந்து வந்த துளசிபட்டி காலம்சென்ற S.நாகசுப்பிரமணியம் என்ற 
                        அழகுபாண்டியன் காப்பாளர் ஸ்தானத்தில் திருமதி.M.வேலம்மாள் அவர்கள் ரூ.5000/- (ரூபாய் ஐந்து ஆயிரம் மட்டும்) 
                        மதிப்புடன் ஒருதான சாசனம் மூலமாக சர்வ சுதந்திர பாத்தியதையுடன் அனுபவிக்கும் மாறு நமது சங்கத்திற்கு இந்த 
                        இடத்தினை ஒரு பதிவு செய்யப்பட்ட ஆவணத்தின் வாயிலாக நன்கொடையாக கொடுத்து இருக்கிற்றார்கள்.
                    </p>
                    <p class="text-dark">
                        இந்த சொத்திற்கு வடபுறமும் மேல்புறமும் முறையே 109அடி மற்றும் 80அடி நடை பாதை பாத்தியதையுடன்.கீழமேல் 
                        அடி 80,தென்வடல் அடி 109 அளவுள்ள 8720அடி (20 சென்ட்) நிலத்தினை ஒரு புறம் பஞ்சாயத்து ரோட்டின் மேல் 
                        மறுபுறம் தூத்துக்குடி செல்லும் தேசிய நெடுஞ்சாலை மேல் அமைத்துள்ள இன்று பல கோடி மதிப்புள்ள இந்த சொத்தினை 
                        பாத்தியப்படுத்தி கொடுத்து இருக்கின்றார்கள்.
                    </p>
                </div>
                <div class="mt-5">
                    <p class="fw-bold">தேவர் மகாஜன சங்கம்</p>
                    <p class="mb-0">க.எண்.35/F8 முதல் F18வரை,</p>
                    <p class="mb-0">எட்டயாபுரம் ரோடு,</p>
                    <p class="mb-0">விளாத்திகுளம்,</p>
                    <p class="mb-0">தூத்துக்குடி மாவட்டம்.</p>
                </div>
                <div class="article-services-content px-0">
                    <h5 class="text-dark mb-4 custom-title"><b>உறுப்பினர் சந்தா விவரம்:</b></h5>
                    <div class="text-dark">
                        <p class="text-dark">உறுப்பினர் பதிவு கட்டணம் <span class="text-danger">Rs 100</span></p>
                        <p class="text-dark">ஆயுட்கால சந்தா <span class="text-danger">Rs 2000</span></p>
                        <p class="text-dark">வருடாந்திர சந்தா <span class="text-danger">Rs 100</span></p>
                    </div>
                </div>
                <!-- <div class="article-services-quote ">
                    <i class="ri-double-quotes-l"></i>
                    <p>"Where distance once divided, our international courier service unites, transforming parcels into bridges that connect hearts, businesses, and aspirations across the globe."</p>

                    <div class="quote-shape">
                        <img src="../../assets/images/services-details/circle-shape.png" alt="image">
                    </div>
                </div> -->
            </div>
            <div class="services-details-shape">
                <img src="../../assets/images/services-details/line-shape.png" alt="image">
            </div>
        </div>

        <!-- <div class="about-circle-shape">
            <img src="../../assets/images/about/about-circle.png" alt="image">
        </div>   -->
    </div>
</template>

<script>
export default {
    name: 'AboutTwo'
}
</script>
<style>
.services-details-desc{
    max-width: none !important;
}
.custom-title b{
    background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
<template>
    <div>
        <Navbar />
        <PageBanner pageTitle="Village" className="page-banner-area" />
        <VillageOne />
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import VillageOne from '../Village/VillageOne'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageOne',
    components: {
        Navbar,
        PageBanner,
        VillageOne,
        Footer,
    }
}
</script>
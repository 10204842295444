<template>
    <div>
        <Navbar />
        <PageBanner pageTitle="Terms And Condition" className="page-banner-area bg-3" />
        <TermsService />
        <Overview />
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import TermsService from '../TermsAndCondition/TermsAndConditionPage'
import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'

export default {
    name: 'TermsAndCondition',
    components: {
        Navbar,
        PageBanner,
        TermsService,
        Overview,
        Footer,
    }
}
</script>
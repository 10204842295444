<template>
    <footer class="footer-area with-black-background ">
        <div class="copyright-area mt-0">
            <div class="container">
                <div class="copyright-area-content">
                    <div class="row">
                        <div class="col-md-5 text-light flex-wrap  text-left mb-3">
                            <p class="text-left d-flex flex-wrap justify-content-left">
                                <router-link to="/terms-and-condition" class="mx-1"> Terms and conditions</router-link> /
                            
                                <router-link to="/privacy-policy" class="mx-1"> Privacy Policy </router-link> /
                            
                                <router-link to="/disclaimer" class="mx-1"> Disclaimer </router-link>
                            </p>
                        </div>
                        <div class="col-md-7 d-flex flex-wrap justify-content-between">
                            <p>
                                &copy; {{currentYear}} <a href="" > Devar Thirumanamahal</a>. All Rights Reserved. Powered by
                                <a href="https://www.roftr.com/" target="_blank"> Roftr</a>
                            </p>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="footer-shape-1">
            <img src="../../assets/images/footer/footer-shape-1.png" alt="image">
        </div>
        <div class="footer-shape-2">
            <img src="../../assets/images/footer/footer-shape-2.png" alt="image">
        </div>
        <div class="footer-shape-3">
            <img src="../../assets/images/footer/footer-shape-3.png" alt="image">
        </div> -->
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>
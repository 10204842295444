<template>
    <div class="about-area pt-100 pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <div class="section-title mb-3" style="max-width: fit-content;">
                        <h2><b>Devar Thirumanamahal</b></h2>
                        <p class="text-dark">விளாத்திகுளம் வட்டாரம் பகுதியில் நம் இன மக்கள் வசிக்கும் கிராமங்கள் விவரம்</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="single-pricing-card bg-white">
                        <h3>விளாத்திகுளம் பேரூராட்சி பகுதியில் உறவுகள் உள்ள இடங்கள்</h3>
                        <ul class="pricing-list">
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> ‌அம்மன் சன்னதி தெரு
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> தேவர் தெரு கீழரதவீதி 
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> மீரான் பாளையம் தெரு 
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> துளசிபட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> சத்யா நகர் 
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>  அம்பாள் நகர் மற்றும் டவுனில் ஆங்காங்கே நம் இன உறவுகள் நிறைய இருக்கிறாங்க
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="single-pricing-card bg-white mb-0">
                        <h3>விளாத்திகுளம் ஒன்றியத்தில் உள்ள நம் இன மக்கள் வசிக்கும் கிராமங்கள்</h3>
                        <ul class="pricing-list">
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> ‌கீழ விளாத்திகுளம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> ‌அரியநாயகிபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> ‌வீர காஞ்சிபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> ‌ஊசிமேசியாபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> தத்தனேரி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> குமார சக்கனாபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> சூரங்குடி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> தங்கம்மாள் புரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> சண்முகாபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> மேல்மாந்தை
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> வேம்பார்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> E.வேலாயுதபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> வைப்பார்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> கீழ வைப்பார்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> கல்லூரணி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> கோட்டைமேடு
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i> புளியங்குளம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>பூசனூர்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>மார்த்தாண்டம் பட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>அயன் செங்கற்படை
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>மீனாட்சிபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>கோட்டநத்தம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>ஜமீன்செங்கற்படை
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>சுப்பிரமணியாபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>கமலா புரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>கூத்தலூரணி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>வள்ளிநாயகிபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>வீரபாண்டிய புரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>முத்துராமலிங்கபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>நமச்சிவாய புரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>சக்கம்மாள்புரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>சிவஞானபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>லட்சுமிபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>அருங்குளம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>பள்ளிக்கூடத்தான் பட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>வெள்ளையம்மாள்புரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>A. செக்கலிங்கபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>கழுகாசலபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>மன்னகோபால நாயக்கன் பட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>தலைக்காட்டுபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>தங்கம்மாள் புரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>படர்ந்தபுளி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>துரைச்சாமி புரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>நீராவி புதுப்பட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>இராமனூத்து
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>பிள்ளையார் நத்தம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>வேலிடுபட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>சிங்கிலி பட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>கல்குமி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>பேரிலோவன்பட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>முதலிபட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>ஆற்றங்கரை
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>சொக்கலிங்கபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>குமராபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>குருவார்பட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>ஜமீன் கோடாங்கிபட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>மகாராஜபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>ஜமீன் கரிசல் குளம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>அயன் பொம்மையாபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>வில்வமரத்துப்பட்டி
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>K.சுந்தரேஷ்வரபுரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>P மீனாட்சி புரம்
                            </li>
                            <li class="text-dark">
                                <i class="ri-loader-2-line text-white"></i>V.வேடபட்டி
                            </li>
                        </ul>
                    </div>
                    <div class="mx-4">
                        <p>மேற்கண்ட கிராமங்கள் விளாத்திகுளம் ஒன்றியத்தில் நம் இன உறவுகள் இருக்கும் பகுதிகள்</p>
                    </div>
                </div>
            </div>
        </div>
    </div>        
</template>
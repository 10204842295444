<template>
    <div>
        <Navbar />
        <PageBanner pageTitle="Contact" className="page-banner-area bg-5" />
        <Overview />
        
        <Map />
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Overview from '../Common/Overview'
//import Talk from '../Common/Talk'
import Map from '../Contact/Map'
import Footer from '../Layouts/Footer'

export default {
    name: 'ContactPage',
    components: {
        Navbar,
        PageBanner,
        Overview,
        Map,
        Footer,
    }
}
</script>
<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image" data-tilt>
                        <img src="../../assets/images/home-images/about-welcome-image.jpg" alt="about-welcome-image" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <!-- <span>Dutch Express</span> -->
                        <h3><b>About Devar Thirumanamahal</b></h3>
                        <p class="text-dark">
                            விளாத்திகுளம் வட்டார தேவர் மகாஜன சங்கம் 1951ம் ஆண்டு துவங்கப்பட்டது.
                        </p>
                        <p class="text-dark">
                            சங்கத்தின் நிதி ஆதாரத்தினை பெருக்கும் பொருட்டு சுற்று வட்டார முக்குலத்தேவர் இன மக்களின் முக்கியமான வருமான
                            வாய்ப்பாக இருக்கிற விவசாய மகசூல் பொருட்களை விற்பனை செய்யும் போது அதற்கு மகிமை வசூல் செய்ய முடிவு 
                            செய்யப்பட்டது.
                        </p>
                        <p class="text-dark">
                            சங்கத்தின் தலைவராக துளசிபட்டி காலம் சென்ற திரு.T. மாடசாமி சேர்வை அவர்களையும் மற்றும் மற்ற கிராமங்களில் 
                            உள்ள முக்கியஸ்தர்கள் அனைவரையும் தேர்வு செய்யப்பட்டது.
                        </p>
                        <div class="about-btn">
                            <router-link to="/about-us" class="default-btn">
                                Know More About Us
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-shape-1">
            <img src="../../assets/images/about/about-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>
<template>
    <div>
        <Navbar />
        <PageBanner pageTitle="Disclaimer" className="page-banner-area bg-5" />
        <Disclaimer />
        <Overview />
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Disclaimer from '../Disclaimer/DisclaimerPage'
import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'

export default {
    name: 'DisclaimerPage',
    components: {
        Navbar,
        PageBanner,
        Disclaimer,
        Overview,
        Footer,
    }
}
</script>
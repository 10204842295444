import { createWebHistory, createRouter } from "vue-router";

import HomePageTwo from "../components/Pages/HomePageTwo";
import AboutPageOne from "../components/Pages/AboutPageOne";
import VillagePage from "../components/Pages/VillagePage";
import ContactPage from "../components/Pages/ContactPage"; 
import TermsAndConditionPage from "../components/Pages/TermsAndCondition"; 
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage"; 
import DisclaimerPage from "../components/Pages/DisclaimerPage"; 

const routes = [
  { path: "/", name: "HomePageTwo", component: HomePageTwo },
  { path: "/about-us", name: "AboutPageOne", component: AboutPageOne },
  { path: "/village", name: "VillagePage", component: VillagePage },
  { path: "/contact-us", name: "ContactPage", component: ContactPage },
  { path: "/terms-and-condition", name: "TermsAndCondition", component: TermsAndConditionPage },
  { path: "/privacy-policy", name: "PrivacyPolicyPage", component: PrivacyPolicyPage },
  { path: "/disclaimer", name: "DisclaimerPage", component: DisclaimerPage } 
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
